body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  padding: 0;
  border: 0;
  height: 100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.title {
  color: #c0a26d;
}

.title-header {
  padding: 15px 15px 9px 15px;
  font-size: 14px;
  color: rgb(136, 136, 136);
  width: 100%;
  box-sizing: border-box;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
}

.trapezoid {
  margin-left: auto;
  margin-right: auto;
  border-radius: 30px;
  background-color: #dbbf7a;
  text-align: center;
  width: 170px;
  font-weight: bold;
  line-height: 35px;
  color: #f94747;
}

.am-card {
  background-color: rgb(162, 221, 235) !important;
}

.am-wingblank {
  background-color: rgb(162, 221, 235);
}

.green-back {
  background-color: rgb(162, 221, 235);
}

.medium-font {
  font-size: 1rem;
  font-weight: bold;
}

.big-font {
  font-size: 1.5rem;
  font-weight: bold;
}

.am-list-item {
  position: relative;
  display: flex;
  padding-left: 15px;
  min-height: 44px;
  background-color: rgb(162, 221, 235) !important;
  vertical-align: middle;
  overflow: hidden;
  transition: background-color 200ms;
  align-items: center;
}

:root:root {
  --adm-button-border-radius: 14px;
  /* --adm-color-background: black; */
}

.am-button {
  padding: 0px 5px !important;
  font-size: 14px !important;
  height: 30px !important;
  line-height: 30px !important;
  /* color: #d81f07 !important; */
  background-color: rgb(240, 153, 73) !important;
  border: 1PX solid rgb(97, 97, 97) !important;
  border-radius: 5px !important;
}

/* .ant-btn-primary {
  color: #d81f07;
  background-color: #3f2908 !important;
  border-color: #3f2908 !important;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.12);
  -webkit-box-shadow: 0 2px 0 rgba(0, 0, 0, 0.045);
  box-shadow: 0 2px 0 rgba(0, 0, 0, 0.045);
  border-radius: 30px;
  line-height: 30px;
}

.ant-btn-primary[disabled] {
  color: #fff !important;
} */

.column-title {
  /* color: #421710; */
  color: rgba(0, 0, 0, 0.7);
  font-size: 14px;
}

.column-value {
  /* color: #421710; */
  color: rgba(0, 0, 0, 0.7);
  font-size: 14px;
}

.am-list-body {
  position: unset !important;
  border-top: unset !important;
  border-bottom: unset !important;
  background-color: unset !important;
  /*border: 1px solid #bba055;*/
  /*border-radius: 5px;*/
}

.balance {
  font-weight: bold;
  color: #ad6800;
}

.am-list-body div:not(:last-child) .am-list-line {
  border-bottom: unset !important;
}

.am-list-line::after {
  transform: scaleY(0) !important;
}

.header {
  width: 100%;
  text-align: center;
}

*,
*:before,
*:after {
  -webkit-tap-highlight-color: unset !important;
}

div {
  color: rgba(0, 0, 0, 0.7);
}

.fake-input {
  color: rgba(0, 0, 0, 0.7) !important;
}

/* input {
  color: rgba(0, 0, 0, 0.7) !important;
} */

.am-modal-mask {
  background-color: rgba(0, 0, 0, 0.7) !important;
}

.am-modal-content {
  background-color: rgb(162, 221, 235) !important;

  color: #fff !important;
}

#root {
  max-width: 600px;
  background: #232323;
}

.clock {
  font-size: 28px;
  font-weight: 800;
  position: relative;
  top: -61px;
}

.am-modal-button-group-h .am-modal-button:last-child {
  border-left: unset !important;
}

.am-list-item:not(:last-child) .am-list-line {
  border-bottom: unset !important;
}

.item-header {
  position: relative;
  display: flex;
  padding-left: 15px;
  padding-right: 15px;
  min-height: 44px;
  background-color: #ab9560;
  vertical-align: middle;
  overflow: hidden;
  transition: background-color 200ms;
  align-items: center;
}

.footer p {
  text-align: center;
  padding: 15px;
  color: #ddd;
}

.am-list-body::after {
  transform: scaleY(0) !important;
}

.am-list-body::before {
  transform: scaleY(0) !important;
}

.contractRule {
  text-align: left;
  overflow-wrap: break-word;

  /* color: #fff; */
  overflow-y: scroll;
  /* font-weight: bold; */
}

.am-modal-transparent {
  width: 90% !important;
}

.am-list-item .am-list-line .am-list-content {
  white-space: unset !important;
}

.am-modal.am-modal-operation .am-modal-content {
  max-height: 400px;
  overflow-y: scroll;
}

.div {
  background-color: rgb(162, 221, 235);
  border-radius: 10px;
  margin-left: 5%;
  width: 90%;
  display: flex;
  flex-direction: column;
  color: #0f0f0f;
  font-weight: bold;
  /*font-size: 1rem;*/
  box-shadow: 0 0 4px rgb(137, 179, 190);
}

.div_flex {
  height: 2rem;
  padding: 0.3rem 0.8rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

button {
  background: rgb(240, 153, 73);
  border-radius: 3px;
  border: solid 1px black;
  padding: 0.4rem 0.6rem;
  font-weight: bold;
}

.div_input {
  border: solid 1px black;
  border-radius: 3px;
  height: 1.5rem;
  width: 40%;
  background: rgb(162, 221, 235);
}

.div_flex_f {
  flex: 1;

}

.z {
  display: flex;
  justify-content: center;
  font-weight: bold;
}

.f {
  display: flex;
  justify-content: space-around;
}

td {
  padding: 0.2rem 0;
  text-align: center;
  font-size: 0.8rem;
}

.hh {
  width: 6rem;
}

.yximg {
  margin-top: -7rem;
  margin-left: -1.6rem;
  position: relative;
  z-index: 1;
}

.login {
  background: rgb(222, 234, 246) center center;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-size: cover;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.login div {
  margin: 1.4rem 0;

}

.login img {
  width: 9.6em;
  height: 7.8rem;
}

.login_div {
  width: 80%;
  height: 4.2rem;
  background: rgb(157, 195, 231);
  border-radius: 0.5rem;
  box-shadow: 1px 4px 8px 0px rgb(162, 170, 177);
  /*水平偏移  垂直偏移  模糊度  阴影扩展  阴影颜色  内外阴影*/
  display: flex;
  justify-content: center;
  align-items: center;
  color: rgb(101, 188, 215);
  font-size: 1.5rem;
}