.dao-title {
    color: #503C82;
    font-size: 1rem;
    /* font-weight: bold; */
    text-align: center;
    background: #FCF0F6;
    border-radius: 8px;
}

.dao-text {
    color: #1f1A17;
    font-size: 1rem;
    font-weight: bold;
    text-align: center;
    /* background: #FCF0F6; */
    border-radius: 8px;
}

.leaderboard-header {
    color: #1f1A17;
    font-size: 1rem;
    /* font-weight: bold; */
    text-align: center;
    /* background: #FCF0F6; */
    /* border-radius: 8px; */
}

.staking-input {
    /* color: red!important; */
    border: 1px solid #4CB4B5;
    border-radius: 3px;
}

.dao-footer {
    font-size: 13px;
}